<template>
  <v-card tile>
    <ERow>
      <ECol cols="12" class="py-0">
        <ERow class="py-0">
          <ECol lg="12" class="py-0">
            <ERow>
              <ECol cols="12" md="4" sm="6">
                <v-tabs v-model="currentTab" @change="onChangeTab">
                  <v-tab key="table"
                    ><v-icon left>fa-border-all</v-icon> Table</v-tab
                  >
                  <v-tab key="graph"
                    ><v-icon left>far fa-chart-bar</v-icon> Graph</v-tab
                  >
                </v-tabs>
              </ECol>
              <v-btn
                v-show="showCloseButton"
                class="close-icon"
                color="primary"
                icon
                dark
                large
                @click="closeDialog()"
              >
                <v-icon>fa-times</v-icon>
              </v-btn>
            </ERow>
            <v-container fluid class="py-0">
              <ERow>
                <ECol align-self="start">
                  <v-select
                    v-model="selectedDateRange"
                    :items="dateRanges"
                    item-text="range"
                    item-value="value"
                    label="Date Range"
                    hide-details
                    outlined
                    dense
                    @change="onChangeSelectDateRange"
                  >
                  </v-select>
                </ECol>
                <v-menu
                  v-if="selectedDateRange == 3"
                  v-model="dateRangePicker"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template #activator="{ on, attrs }">
                    <ECol cols="12" md="2" sm="6">
                      <v-text-field
                        v-model="displayDateRange"
                        placeholder="From - To"
                        readonly
                        dense
                        hide-details
                        :loading="loading"
                        v-bind="attrs"
                        label="From/To Date"
                        outlined
                        v-on="on"
                      />
                    </ECol>
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    range
                    @change="dateRangePicker = false"
                  />
                </v-menu>
                <v-spacer v-if="selectedDateRange != 3" />
                <ECol
                  cols="12"
                  md="4"
                  sm="6"
                  offset-md="0"
                  offset-sm="6"
                  class="text-right mr-1"
                >
                  <CopyTableToClipboard
                    v-if="currentTab == 0"
                    :headers="headers"
                    :items="filteredItems"
                  />
                  <ShowHide
                    v-if="currentTab == 0"
                    :table-fields="headers"
                    :selected-headers-list="selectedHeaders"
                    @update-selectedHeaders="updateSelectedHeaders"
                  />
                  <v-btn-toggle
                    v-if="currentTab == 1"
                    v-model="selectedChart"
                    mandatory
                    tile
                    color="primary"
                  >
                    <v-btn small value="battery-voltage">Battery Voltage</v-btn>

                    <v-btn small value="voltage-summary">Voltage Summary</v-btn>

                    <v-btn small value="battery-voltages-summary"
                      >Battery Voltages Summary</v-btn
                    >
                  </v-btn-toggle>
                </ECol>
                <v-spacer v-if="currentTab == 1" />
              </ERow>
            </v-container>
          </ECol>
        </ERow>

        <v-tabs-items v-model="currentTab">
          <v-tab-item key="table">
            <ERow justify="center" no-gutters>
              <ECol ref="tableContainer" cols="12" class="pa-0">
                <v-data-table
                  ref="batteryReadings"
                  v-model="selected"
                  :headers="showHeaders"
                  :items="readings"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  class="custom-data-table elevation-1"
                  dense
                  must-sort
                  :loading="loading"
                  loading-text="Please wait..."
                  calculate-widths
                  :mobile-breakpoint="0"
                  :options.sync="options"
                  :server-items-length="total"
                  item-key="id"
                  :footer-props="{
                    'items-per-page-options': [50, 100, 200],
                  }"
                  :height="tableHeight"
                  :fixed-header="true"
                >
                  <template #item.datetime="{ item }">
                    {{ formatDate(item.datetime) }}
                  </template>
                  <template #item.voltage="{ item }">
                    {{ (item.voltage / 1000).toFixed(2) }}
                  </template>
                  <template #item.iValue="{ item }">
                    {{ (item.iValue / 1000).toFixed(2) }}
                  </template>
                  <template #item.vpvValue="{ item }">
                    {{ (item.vpvValue / 1000).toFixed(2) }}
                  </template>
                  <template #item.ilValue="{ item }">
                    {{ (item.ilValue / 1000).toFixed(2) }}
                  </template>

                  <template #item.h19Value="{ item }">
                    {{ item.h19Value * 1000 }}
                  </template>
                  <template #item.h20Value="{ item }">
                    {{ item.h20Value * 1000 }}
                  </template>
                  <template #item.h22Value="{ item }">
                    {{ item.h22Value * 1000 }}
                  </template>
                </v-data-table>
              </ECol>
            </ERow>
          </v-tab-item>
          <v-tab-item key="graph">
            <v-card outlined class="ma-1">
              <v-card-text class="pa-0">
                <highcharts
                  v-if="selectedChart === 'battery-voltage'"
                  :options="voltageChartOptions"
                  style="height: 80vh"
                ></highcharts>
                <highcharts
                  v-if="selectedChart === 'voltage-summary'"
                  :options="batteryVsSolarPanelChartOptions"
                  style="height: 80vh"
                ></highcharts>
                <highcharts
                  v-if="selectedChart === 'battery-voltages-summary'"
                  :options="minMaxVoltageChartOptions"
                  style="height: 80vh"
                ></highcharts>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </ECol>
    </ERow>
  </v-card>
</template>

<script>
import batteryReadingHeaders from "@/components/batteries/batteryReadingsHeaders.js"
import VueHighcharts from "@evercam/shared/components/Highcharts"
import { decamelize } from "humps"
import ShowHide from "@/components/ShowHide"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import { EvercamApi } from "@evercam/shared/api/evercamApi"

export default {
  components: {
    highcharts: VueHighcharts,
    ShowHide,
    CopyTableToClipboard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedChart: "battery-voltage",
      selected: [],
      displayDateRange: "",
      dateRangePicker: false,
      dateRange: [
        this.$moment().format("YYYY-MM-DD"),
        this.$moment().format("YYYY-MM-DD"),
      ],
      isGraphInitialized: false,
      currentTab: 1,
      loading: true,
      sortBy: "datetime",
      sortDesc: true,
      graphReadings: [],
      selectedHeaders: [],
      headers: batteryReadingHeaders,
      total: 0,
      readings: [],
      options: {
        sortBy: ["datetime"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 50,
      },
      xAxisSteps: 1,
      fromDate: this.$moment().subtract(2, "d").format("YYYY-MM-DD"),
      toDate: this.formatDate(new Date(), "YYYY-MM-DD"),
      categoriesDates: [],
      maximumVoltages: [],
      minimumVoltages: [],
      dateRanges: [
        { range: "Last Day", value: 0 },
        { range: "Last 7 Days", value: 1 },
        { range: "Last 30 Days", value: 2 },
        { range: "Custom", value: 3 },
      ],
      selectedDateRange: 0,
      voltageChartOptions: {
        chart: {
          type: "area",
          zoomType: "xy",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Battery Voltage",
        },
        subtitle: {
          text: "Time Vs. Voltage",
        },
        xAxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "proxima-nova,helvetica,arial,sans-serif",
              whiteSpace: "nowrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
            },
          },
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, "#fff"],
                [1, "#7cb5ec"],
              ],
            },
            marker: {
              radius: 2,
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1,
              },
            },
            threshold: null,
          },
        },
        yAxis: {
          title: {
            text: "Voltages",
          },
        },
        tooltip: {
          valueSuffix: " V",
        },
        series: [
          {
            name: "Voltage",
            data: [],
          },
        ],
      },
      batteryVsSolarPanelChartOptions: {
        chart: {
          type: "line",
          zoomType: "xy",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Voltage Summary",
        },
        subtitle: {
          text: "Battery Vs. Solar panel",
        },
        xAxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "proxima-nova,helvetica,arial,sans-serif",
              whiteSpace: "nowrap",
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: "10px",
              paddingBottom: "10px",
            },
          },
        },
        yAxis: {
          title: {
            text: "Voltages",
          },
        },
        tooltip: {
          valueSuffix: " V",
          shared: true,
        },
        series: [
          {
            name: "Battery Voltage",
            data: [],
          },
          {
            name: "Panel Voltage",
            data: [],
          },
        ],
      },
      minMaxVoltageChartOptions: {
        chart: {
          type: "column",
          zoomType: "xy",
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Battery Voltages Summary",
        },
        subtitle: {
          text: "Date wise",
        },
        xAxis: {
          categories: [],
          crosshair: true,
        },
        yAxis: {
          title: {
            text: "Voltages",
          },
        },
        tooltip: {
          valueSuffix: " V",
          shared: true,
        },
        series: [
          {
            name: "Maximum Voltage",
            data: [],
          },
          {
            name: "Minimum Voltage",
            data: [],
          },
        ],
      },
      tableHeight: null,
    }
  },
  computed: {
    showHeaders() {
      return this.headers.filter((s) => this.selectedHeaders.includes(s))
    },
    filteredItems() {
      let filteredItems = this.$refs.batteryReadings?.internalCurrentItems

      return filteredItems ? filteredItems : this.readings
    },
  },
  watch: {
    options: {
      handler() {
        this.initialize()
      },
      deep: true,
    },
    dateRange: {
      handler() {
        if (this.dateRange.length == 2) {
          this.updateDatePicker()
          this.initialize()
        }
      },
    },
    showCloseButton(isVisible) {
      if (isVisible) {
        this.updateDatePicker()
        this.initialize()
      }
    },
  },
  created() {
    this.updateDatePicker()
    this.initialize()
  },
  methods: {
    updateDatePicker() {
      if (this.dateRange.length == 2) {
        if (
          this.$moment(this.dateRange[0]).isAfter(
            this.$moment(this.dateRange[1])
          )
        ) {
          this.displayDateRange = this.dateRange[1] + "-" + this.dateRange[0]
          this.fromDate = this.dateRange[1]
          this.toDate = this.dateRange[0]

          return
        }
        this.displayDateRange = this.dateRange.join(" / ")
        this.fromDate = this.dateRange[0]
        this.toDate = this.dateRange[1]
      }
    },
    onChangeSelectDateRange(selectedRange) {
      if (selectedRange === 0)
        this.dateRange = [
          this.$moment().format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD"),
        ]
      else if (selectedRange === 1)
        this.dateRange = [
          this.$moment().subtract({ days: 7 }).format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD"),
        ]
      else if (selectedRange === 2)
        this.dateRange = [
          this.$moment().subtract({ days: 30 }).format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD"),
        ]
      else
        this.dateRange = [
          this.$moment().subtract({ days: 2 }).format("YYYY-MM-DD"),
          this.$moment().format("YYYY-MM-DD"),
        ]
    },
    onChangeTab() {
      this.initialize()
    },
    updateSelectedHeaders(value) {
      this.selectedHeaders = value
    },
    async initialize() {
      if (this.currentTab === 1) {
        this.getGraphReadings()
      } else {
        this.selectedHeaders = Object.values(this.headers).filter((h) => {
          return h.visible !== false
        })
        this.getTableReadings()
      }
      this.$nextTick(() => {
        this.onResize()
        this.$addEventListener("resize", this.onResize)
      })
    },
    async getTableReadings() {
      try {
        if (!this.id) return
        this.loading = true
        let { sortBy, sortDesc, page, itemsPerPage } = this.options

        sortBy = decamelize(sortBy[0])
        let params = {
          sort: `${sortBy}|${this.whichSort(sortDesc[0])}`,
          limit: itemsPerPage,
          page: page,
          fromDate: this.fromDate,
          toDate: this.toDate,
        }

        const response = await EvercamApi.batteries.batteryReadings(
          this.id,
          params
        )
        this.total = response.total
        this.readings = response.items
      } catch (error) {
        this.$notifications.error({
          text: "An error occurred fetching batteries!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    async getGraphReadings() {
      try {
        if (!this.id) return
        this.loading = true
        this.isGraphInitialized = false

        let pages = 1,
          readingItems = [],
          total = null,
          continueSearch = true
        do {
          let responseGraph = await EvercamApi.batteries.batteryReadings(
            this.id,
            {
              sort: "datetime|desc",
              limit: 1000,
              page: pages,
              fromDate: this.fromDate,
              toDate: this.toDate,
            }
          )
          if (pages != responseGraph.page) {
            continueSearch = false
            break
          }
          total = responseGraph.total
          readingItems.push(...responseGraph.items)
          pages++
        } while (continueSearch)
        this.total = total
        this.graphReadings = readingItems
        this.getGraphData()
      } catch (error) {
        this.$notifications.error({
          text: "An error occurred fetching batteries!",
          error,
        })
      } finally {
        this.loading = false
      }
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
    getGraphData() {
      this.initGraphsData()
      this.isGraphInitialized = true
    },
    timestampDisplay(timestamps) {
      this.xAxisSteps = Math.floor(timestamps.length / 40)
      timestamps = timestamps.reverse()
      let arrangedTimestamps = [...timestamps]

      // Displaying time differently if the days chosen are more than 10 days
      if (
        Math.abs(
          this.$moment(this.dateRange[0]).diff(
            this.$moment(this.dateRange[1]),
            "days"
          )
        ) < 10
      ) {
        // Only keeping the hours and minutes
        arrangedTimestamps = arrangedTimestamps.map((time, index) =>
          index % this.xAxisSteps == 0 ? this.$moment(time).format("HH:mm") : ""
        )

        // Displaying the dates when going into a new day
        let lastTimeStampIndex = 0
        arrangedTimestamps = arrangedTimestamps.map((time, index) => {
          if (time != "") {
            let isNewDay =
              this.$moment(timestamps[lastTimeStampIndex], "DD MMM YYYY").diff(
                this.$moment(timestamps[index], "DD MMM YYYY"),
                "days"
              ) != 0
            let isNewYear =
              this.$moment(timestamps[lastTimeStampIndex]).year() !=
              this.$moment(timestamps[index]).year()
            lastTimeStampIndex = index
            if (isNewDay || index == 0) {
              if (!isNewYear) {
                return this.$moment(timestamps[index]).format(
                  "DD MMM YYYY HH:mm"
                )
              } else {
                return this.$moment(timestamps[index]).format("DD MMM HH:mm")
              }
            }
          }

          return time
        })
      } else {
        // only show date when displaying more than 10 days
        arrangedTimestamps = arrangedTimestamps.map((time) => {
          return this.$moment(time).format("DD MMM YYYY")
        })
      }

      return arrangedTimestamps
    },
    initGraphsData() {
      let readingsTimestamps = []
      let batteryVoltages = []
      let panelVoltages = []

      let increment = 1
      if (this.selectedDateRange != 0)
        increment = Math.round(this.graphReadings.length / 200)

      for (let i = 0; i < this.graphReadings.length; i += increment) {
        const reading = this.graphReadings[i]
        batteryVoltages.push(this.convertUnit(reading.voltage))
        panelVoltages.push(this.convertUnit(reading.vpvValue))
        readingsTimestamps.push(
          this.$moment(reading.datetime).format("DD MMM YYYY HH:mm")
        )
      }
      // arranging time on the labels
      let readingsTimestampsLabels = this.timestampDisplay(readingsTimestamps)

      let dataSet = []
      batteryVoltages = batteryVoltages.reverse()
      panelVoltages = panelVoltages.reverse()
      readingsTimestamps.forEach((datetime, i) => {
        let item = {
          datetime: datetime,
          labelDateTime: readingsTimestampsLabels[i],
          voltage: batteryVoltages[i],
          panelVoltages: panelVoltages[i],
        }
        dataSet.push(item)
      })

      let capitalsList = dataSet.sort((a, b) =>
        this.$moment(a.datetime).isBefore(this.$moment(b.datetime)) ? -1 : 1
      )

      let categoryListNew = []
      let batteryVoltagesNew = []
      let panelVoltagesNew = []
      capitalsList.forEach((data) => {
        categoryListNew.push(data.labelDateTime)
        batteryVoltagesNew.push([data.datetime, data.voltage])
        panelVoltagesNew.push(data.panelVoltages)
      })

      this.setVoltageChartData(categoryListNew, batteryVoltagesNew)
      this.setBatteryVsSolarPanelChartData(
        categoryListNew,
        batteryVoltagesNew,
        panelVoltagesNew
      )

      EvercamApi.batteries
        .readingsVoltageSummary(this.id, {
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
        .then((history) => {
          let categoriesDates = []
          let maximumVoltages = []
          let minimumVoltages = []
          history.forEach((data) => {
            let minValue = data.minValue
            if (minValue == null) {
              minValue = 0
            }
            let maxValue = data.maxValue
            if (maxValue == null) {
              maxValue = 0
            }

            let date = this.$moment(data.date, "YYYY-MM-DD").format(
              "DD MMM YYYY"
            )

            categoriesDates.push(date)
            maximumVoltages.push(maxValue)
            minimumVoltages.push(minValue)
          })
          this.setMinMaxVoltageChartData(
            categoriesDates,
            maximumVoltages,
            minimumVoltages
          )
        })
    },
    setVoltageChartData(categoryList, batteryVoltages) {
      this.voltageChartOptions = {
        ...this.voltageChartOptions,
        xAxis: {
          categories: categoryList,
          labels: {
            step: this.xAxisSteps,
            rotation: -45,
          },
        },
        series: [
          {
            name: "Voltage",
            data: batteryVoltages,
            color: "#47bcfa",
          },
        ],
      }
    },
    setBatteryVsSolarPanelChartData(
      categoryList,
      batteryVoltages,
      panelVoltages
    ) {
      this.batteryVsSolarPanelChartOptions = {
        ...this.batteryVsSolarPanelChartOptions,
        xAxis: {
          categories: categoryList,
          labels: {
            step: this.xAxisSteps,
            rotation: -45,
          },
        },
        series: [
          {
            name: "Battery Voltage",
            data: batteryVoltages,
            color: "#47bcfa",
          },
          {
            name: "Panel Voltage",
            data: panelVoltages,
            color: "#363636",
          },
        ],
      }
    },
    setMinMaxVoltageChartData(
      categoriesDates,
      maximumVoltages,
      minimumVoltages
    ) {
      this.minMaxVoltageChartOptions = {
        ...this.minMaxVoltageChartOptions,
        xAxis: {
          categories: categoriesDates,
          labels: {
            step: 0,
          },
        },
        series: [
          {
            name: "Maximum Voltage",
            data: maximumVoltages,
            color: "#363636",
          },
          {
            name: "Minimum Voltage",
            data: minimumVoltages,
            color: "#47bcfa",
          },
        ],
      }
    },
    convertUnit(value) {
      if (value) {
        return value / 1000
      } else {
        return 0
      }
    },
    onResize() {
      const tableTop =
        this.$refs.tableContainer?.$el?.getBoundingClientRect()?.top || 0
      this.tableHeight = window.innerHeight - tableTop
    },
    closeDialog() {
      this.$emit("closed", true)
    },
  },
}
</script>
